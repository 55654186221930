import React, { useEffect, useState } from "react";
import BN from "bignumber.js";
import { isNil, isEmpty, countBy, reject, includes } from "ramda";
import { JsonRpcProvider } from "@ethersproject/providers";

import "@fontsource/work-sans/500.css";

import ENSLogo from "./images/ENS-logo.svg";
import ENSIcon from "./images/icon.svg";
import OVRLogo from "./images/logo-over.png";

const endpointUrl = "https://mws.ovr.ai/services/ens/info";

export const toHexId = (nftId) => new BN(nftId).toString(16);

const loadConfig = async () => {
  try {
    const response = await fetch(endpointUrl, { method: "GET" });
    const res = await response.json();
    const {
      result = false,
      data: { defaultSite = null, provider = null },
    } = res;

    if (result) {
      return { defaultSite, provider };
    }

    return null;
  } catch (err) {
    console.error("Error", err);
    return null;
  }
};

const guessSubdomain = (slices) => {
  const final = reject((single) =>
    includes(single, [
      "https",
      "http",
      "localhost",
      "ovr",
      "eth",
      "link",
      "limo",
    ])
  )(slices);
  if (isEmpty(final)) return null;
  return final[0];
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [nftId, setNftId] = useState("");
  const [hexId, setHexId] = useState("");
  const [defaultSite, setDefaultSite] = useState("");
  const [subdomain, setSubdomain] = useState("");

  async function ensRedirect() {
    const config = await loadConfig();
    if (config == null) {
      return;
    }

    const { defaultSite, provider: givenProvider } = config;
    console.debug("Config", defaultSite);
    setDefaultSite(defaultSite);
    const provider = new JsonRpcProvider(givenProvider);

    const url = window.location.hostname;

    const isLink = url.includes(".link") || url.includes(".limo");

    const slices = url.split(/[.//_:/]+/);
    const { true: ovrCounts } = countBy((c) => c === "ovr")(slices);
    let subdomain = guessSubdomain(slices);

    console.debug("Data", { isLink });
    console.debug("Subdomain", { subdomain, slices });

    try {
      if (!isNil(subdomain)) {
        setSubdomain(subdomain);
      } else {
        if (ovrCounts > 1) {
          subdomain = "ovr";
          setSubdomain("ovr");
        } else {
          subdomain = null;
        }
      }

      if (isNil(subdomain)) {
        setLoading(false);
        setSubdomain("");

        return;
      } else {
        const resolver = await provider.getResolver(`${subdomain}.ovr.eth`);
        const nftId = await resolver.getText("notice");
        console.debug("OVRLand nftId:", nftId);

        if (isEmpty(nftId) || isNil(nftId)) {
          setLoading(false);
        } else {
          setNftId(nftId);
          const hexId = toHexId(nftId.toString());
          setHexId(hexId);
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setSubdomain("");
      console.error("Error", err);
    }

    setLoading(false);
  }

  useEffect(() => {
    ensRedirect();
  }, []);
  return (
    <div className="App">
      <div className="container">
        <div className="container-logos">
          <img src={OVRLogo} className="ovr-logo" alt="OVER Logo" />
          <span className="divider">x</span>
          <img src={ENSLogo} className="ens-logo" alt="ENS Logo" />
        </div>

        <div
          className={`container-domain ${
            !isEmpty(subdomain) && !isEmpty(nftId) ? "visible" : ""
          }`}
        >
          <div className="domain">
            <img src={ENSIcon} className="ens-logo-domain" alt="Icon" />
            <span>{subdomain}.ovr.eth</span>
          </div>
        </div>
        <div
          className={
            !isEmpty(subdomain) && !isEmpty(nftId)
              ? "content visible"
              : "content"
          }
        >
          <hr />
          <p className="text">Please choose where you want to be redirected</p>
          <div className="container-buttons">
            <a
              className="button marketplace"
              rel="noreferrer noopener"
              href={`${defaultSite}${nftId}`}
            >
              Open in Marketplace
            </a>
            <a
              className="button"
              rel="noreferrer noopener"
              href={`https://link.ovr.ai/?link=https://marketplace.ovr.ai/land/${hexId}&afl=&amv=0&apn=com.gezapp.ovr&ibi=com.gezapp.ovr&ifl=&ipfl=&isi=1463400310&sd=The%20decentralized%20world%20scale%20augmented%20reality%20platform.%20OVR%20is%20a%20worldwide,%20open-source,%20AR%20platform%20powered%20by%20the%20Ethereum%20Blockchain.&si=https://www.ovr.ai/wp-content/uploads/static/social_img.jpg&st=OVR%20%E2%80%93%20Augmented%20reality%20platform/`}
            >
              Open in App
            </a>
          </div>
        </div>
        <div
          className={`error ${
            isEmpty(subdomain) && !loading ? "visible" : ""
          } `}
        >
          Invalid or Missing subdomain or OVRLand not linked
        </div>
        <div className={`container-loading ${!loading && "loaded"}`}>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
